import classNames from 'classnames';
import { useAppState } from '../../app/contexts/AppStateContext';
import { PageRenderingError } from '../../app/errors';
import { createPageUrl } from '../../app/redirects';
import settings from '../../app/settings';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ICelestialEventsMultipleDays } from '../../model/celestialEvents';
import { IForecast } from '../../model/forecast';
import { ILocation } from '../../model/location';
import { IWarning } from '../../model/warning';
import { CelestialEvents } from '../CelestialEvents/CelestialEvents';
import { ExpandableWarningList } from '../ExpandableWarningList/ExpandableWarningList';
import { HourlyWeatherTable } from '../HourlyWeatherTable/HourlyWeatherTable';
import { ModalDialog } from '../ModalDialog/ModalDialog';
import { RelativeTime } from '../RelativeTime/RelativeTime';
import './HourlyWeatherDialog.scss';

export interface IProps {
  index: number;
  location: ILocation;
  forecast: IForecast;
  warnings: IWarning[];
  celestialEvents?: ICelestialEventsMultipleDays;
}

export default function HourlyWeatherDialog(props: IProps) {
  const { index, location, forecast, warnings, celestialEvents } = props;

  const { currentPage } = useAppState();
  const localeCode = useLocaleCode();
  const translate = useTranslate();

  const { pageId, subpageId } = currentPage.details.params;

  const forecastIntervalGroupedByDay = forecast.intervalsGroupedByDay[index];

  // TODO(tl): When the designers want to release the link to sun- and mooncard, uncomment this
  // const href = createPageUrl({
  //   localeCode,
  //   pageId: 'other-conditions',
  //   locationId: location.id,
  //   urlPath: location.urlPath,
  //   hash: `#${translate(SUN_AND_MOON_CARD_ID_LOKALISE_KEY)}`
  // });

  // Can be undefined query parameter is changed manually. See YR-4838.
  if (forecastIntervalGroupedByDay == null) {
    throw new PageRenderingError();
  }

  const { start } = forecastIntervalGroupedByDay;

  const queryParameter = settings.path.query.index;
  const nextIndex = index < forecast.dayIntervals.length - 1 ? index + 1 : undefined;
  const previousIndex = index > 0 ? index - 1 : undefined;

  const hasWarnings = warnings != null && warnings.length > 0;

  const previousUrl =
    previousIndex != null
      ? createPageUrl({
          localeCode,
          pageId,
          subpageId,
          locationId: location.id,
          urlPath: location.urlPath,
          search: `?${queryParameter}=${previousIndex}`
        })
      : undefined;

  const nextUrl =
    nextIndex != null
      ? createPageUrl({
          localeCode,
          pageId,
          subpageId,
          locationId: location.id,
          urlPath: location.urlPath,
          search: `?${queryParameter}=${nextIndex}`
        })
      : undefined;

  const onCloseUrl = createPageUrl({
    localeCode,
    pageId,
    subpageId: 'daily-table',
    locationId: location.id,
    urlPath: location.urlPath
  });

  return (
    <ModalDialog
      renderOnServer={true}
      title={
        <RelativeTime
          type="relative-day-with-date-long"
          time={start}
          relativeTodayOnly={true}
          transform="sentence-case"
        />
      }
      previousUrl={previousUrl}
      nextUrl={nextUrl}
      onCloseUrl={onCloseUrl}
    >
      <div className="hourly-weather-dialog" data-has-warnings={hasWarnings}>
        {hasWarnings ? <ExpandableWarningList warnings={warnings} /> : null}
        <div
          className={classNames({
            'hourly-weather-dialog__table': true
          })}
        >
          <HourlyWeatherTable
            type="simple"
            tableCaption={translate('hourlyWeatherDialog/accessibleFallback')}
            intervalsGroupedByDay={forecastIntervalGroupedByDay}
          />
        </div>
        <div className="hourly-weather-dialog__celestial_events_container">
          <div className="hourly-weather-dialog__celestial_events">
            <CelestialEvents start={start} celestialEvents={celestialEvents} />
          </div>
          {/* TODO(tl): When the designers want to release the link to sun- and mooncard, uncomment this */}
          {/* <Text as="div" size="5" isLink={true} className="hourly-weather-dialog__celestial_events-link-sun-card">
            <ClickableLink href={href}>{translate('celestialEvents/moreAboutSunLink')}</ClickableLink>
          </Text> */}
        </div>
      </div>
    </ModalDialog>
  );
}
