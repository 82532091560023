import { Icon } from '@nrk/yr-icons';
import { isSameDate } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ICelestialEventsMultipleDays } from '../../model/celestialEvents';
import { createSunEvents } from '../SunCard/helpers/data';
import { Text } from '../Text/Text';
import './CelestialEvents.scss';
interface IProps {
  start: string;
  celestialEvents?: ICelestialEventsMultipleDays;
}

export function CelestialEvents(props: IProps) {
  const { start, celestialEvents } = props;
  const translate = useTranslate();

  if (celestialEvents == null) {
    return null;
  }

  const currentDay = celestialEvents.days.find(day => {
    return isSameDate({ date: start, dateToCompare: day.date });
  });

  if (currentDay == null) {
    return null;
  }

  const sunEvents = createSunEvents({ sunEvents: currentDay.sun.events, translate, type: 'celestial' });

  return (
    <div className="celestial-events">
      {sunEvents.map(event => {
        return (
          <div className="celestial-events__event" key={event.sortByTime + event.iconId}>
            <Icon className="celestial-events__icon" id={event.iconId} size={2} />
            <Text size="5" className="celestial-events__event-text">
              {event.label}
            </Text>
          </div>
        );
      })}
    </div>
  );
}
